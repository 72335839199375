<template>
  <v-container>
    <v-row dense>
      <v-col
        cols="12"
        sm="6"
        class="text-center"
      >
        <img src="../assets/img/logo_GC.png">
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="text-center"
      >
        <h1>{{ $t('license_tt') }}</h1>
        <h3>{{ $t('license_subtt') }}</h3>
      </v-col>
      <v-col
        cols="12"
        class="pa-3"
      >
        {{ $t('license_nei') }}
      </v-col>
    </v-row>

    <swiper
      class="swiper my-3"
      :options="swiperS2"
    >
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license01.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license02.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license03.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license04.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license05.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license06.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license07.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license08.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license09.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license10.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license11.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license12.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license13.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license14.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div>
          <v-img
            src="../assets/img/pic/license15.jpg"
            alt=""
          />
        </div>
      </swiper-slide>
      <div
        slot="button-prev"
        class="swiper-button-prev swiper-navBtn S2-prev"
      />
      <div
        slot="button-next"
        class="swiper-button-next swiper-navBtn S2-next"
      />
      <div
        slot="pagination"
        class="swiper-pagination S2-pg"
      />
    </swiper>
  </v-container>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'License',
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    swiperS2: {
      effect: "coverflow",
      slidesPerView: 2.2,
      spaceBetween: 8,
      centeredSlides: true,
      fade: true,
      grabCursor: true,
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 400,
        modifier: 1,
        slideShadows: true,
      },
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.S2-pg',
        clickable: true
      },
      navigation: {
        nextEl: '.S2-next',
        prevEl: '.S2-prev'
      }
    },
  }),
}
</script>
<style>
  .swiper-navBtn{
      color: rgba(255, 255, 255, .3)!important;
      transition: color 0.3s ease;
  }
  .swiper-navBtn:hover{
      color: rgba(255, 255, 255, .8)!important;
  }
      .swiper-navBtn::before,
      .swiper-navBtn::after{
      font-size: 35px;
  }

  .swiper-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1!important; 
  }

  .swiper-pagination-bullet {
      width: 0.625rem!important;
      height: 0.625rem!important;
      border-radius: .3125rem!important;
      background-color: var(--v-box_bg-base)!important;
      margin: 0 0.3125rem!important;
      cursor: pointer;
      transition: width .3s linear;
      opacity: 0.4!important;
  }

  .swiper-pagination-bullet-active {
      background-color: var(--v-box_bg-base)!important;
      width: 1.75rem!important;
      opacity: 0.8!important;
  }

  @media (max-width: 767px) {
    /* 在移动端应用的样式 */
    .down-btn {
      bottom: 65px;
    }
  }

  @media (min-width: 768px) {
    /* 在PC端应用的样式 */
    .down-btn {
      bottom: 0px;
    }
  }
</style>